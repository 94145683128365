import React, { useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import * as speechify from '../../lib/speechify';
import { getInitials } from '../../utils';
import { md5 } from '../../utils/md5';

interface AvatarProps {
  email: string;
  name: string;
  showInitials?: boolean;
  size?: number;
}

export const Avatar: React.FC<AvatarProps> = ({ email, name, size = 32, showInitials = true }) => {
  const initials = useMemo(() => getInitials(name), [name]);
  const md5Email = useMemo(() => md5(email || ''), [email]);

  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (email === speechify.auth.currentUser?.email && speechify.auth.currentUser?.photoURL) {
      setSrc(speechify.auth.currentUser?.photoURL);
    } else {
      setSrc(`https://www.gravatar.com/avatar/${md5Email}?s=${Math.max(size, 250)}&d=mp`);
    }
    // ESLint: React Hook useEffect has missing dependencies: 'md5Email' and 'size'. Either include them or remove the dependency array. Outer scope values like 'speechify.auth.currentUser' aren't valid dependencies because mutating them doesn't re-render the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, speechify.auth.currentUser]);

  const handleImgError = () => {
    setSrc(`https://www.gravatar.com/avatar/${md5Email}?s=${Math.max(size, 250)}&d=mp`);
  };

  return (
    <div
      className={twMerge('inline-flex', 'items-center', 'justify-center', 'overflow-hidden', 'rounded-full', 'text-zinc-600')}
      style={{
        width: size,
        height: size
      }}
    >
      {showInitials && (
        <div
          aria-hidden="true"
          className="absolute select-none font-sans"
          style={{
            fontSize: size / (1.4 * Math.max([...initials].length, 2))
          }}
        >
          {initials}
        </div>
      )}
      <img
        className={twMerge('relative', 'rounded-full')}
        style={{
          height: size,
          objectFit: 'cover',
          width: size
        }}
        src={src}
        alt={name}
        onError={handleImgError}
      />
    </div>
  );
};

export default Avatar;
