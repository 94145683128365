import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { profilingStoreActions } from 'modules/profiling/profilingStore';
import { actions as routerActions } from 'store/router';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

export type NavigateFunction = (url: string, as?: string, options?: TransitionOptions) => void;

export function useNavigate(): NavigateFunction {
  const router = useRouter();
  const dispatch = useDispatch();

  return useCallback(
    (url: string, as?: string, options?: TransitionOptions) => {
      profilingStoreActions.navigate();
      dispatch(routerActions.navigate());
      router.push(url, as, options);
    },
    [dispatch, router]
  );
}
